
<template>
  <div class="heard">
    <div class="heard-center space-between height-100 pointer">
      <img src="../assets/logo.png" alt="" @click="navTo('/')" />
      <div class="row">
        <div
          class="item_nav pointer"
          :class="{
            item_nav_active: item.url == url,
          }"
          v-for="item in navList"
          :key="item.url"
          @click="navTo(item.url)"
        >
          {{ item.text }}
        </div>
        <div class="item_nav item_nav_text pointer" @click="navTo('/login')">LOGIN</div>
      </div>
    </div>
    <div class="left">

          <div class="gt_float_wrapper" >
              <div class="gt_float_switcher notranslate" style="opacity: 1;">
                <div class="gt-selected" @click="is_show=!is_show">
                  <div class="gt-current-lang" :style="{ width: is_show ? '234px' : 'auto' }">
                    <img :src="is_default.img" alt="pt">
                    <span class="gt-lang-code">{{is_default.title}}</span>
                    <span class="gt_float_switcher-arrow" :class="{ 'gt_arrow_rotate': is_show }">
                    </span>
                  </div>
                </div>
                <div class="gt_options open gt-open" v-show="is_show" style="width: 100%">
                  <a href="#"  @click="translate(index)" v-for="(item,index) in data" :key="index">
                    <img   :src="item.img" /> {{item.title}}
                  </a>
                  <!-- <a href="#" class="nturl gt-current" data-gt-lang="en">
                    <img data-gt-lazy-src="https://cdn.gtranslate.net/flags/svg/en-us.svg" alt="en" src="https://cdn.gtranslate.net/flags/svg/en-us.svg"> English
                  </a>
                  <a href="#" class="nturl" data-gt-lang="pt">
                    <img data-gt-lazy-src="https://cdn.gtranslate.net/flags/svg/pt.svg" alt="pt" src="https://cdn.gtranslate.net/flags/svg/pt.svg"> Português
                  </a>
                  <a href="#" class="nturl" data-gt-lang="es">
                    <img data-gt-lazy-src="https://cdn.gtranslate.net/flags/svg/es.svg" alt="es" src="https://cdn.gtranslate.net/flags/svg/es.svg"> Español
                  </a>
                  <a href="#" class="nturl" data-gt-lang="fr">
                    <img data-gt-lazy-src="https://cdn.gtranslate.net/flags/svg/fr.svg" alt="fr" src="https://cdn.gtranslate.net/flags/svg/fr.svg"> Français
                  </a>
                  <a href="#" class="nturl" data-gt-lang="it">
                    <img data-gt-lazy-src="https://cdn.gtranslate.net/flags/svg/it.svg" alt="it" src="https://cdn.gtranslate.net/flags/svg/it.svg"> Italiano
                  </a>
                  <a href="#" class="nturl" data-gt-lang="de">
                    <img data-gt-lazy-src="https://cdn.gtranslate.net/flags/svg/de.svg" alt="de" src="https://cdn.gtranslate.net/flags/svg/de.svg"> Deutsch
                  </a>
                  <a href="#" class="nturl" data-gt-lang="ru">
                    <img data-gt-lazy-src="https://cdn.gtranslate.net/flags/svg/ru.svg" alt="ru" src="https://cdn.gtranslate.net/flags/svg/ru.svg"> Русский
                  </a>
                  <a href="#" class="nturl" data-gt-lang="zh-CN">
                    <img data-gt-lazy-src="https://cdn.gtranslate.net/flags/svg/zh-CN.svg" alt="zh-CN" src="https://cdn.gtranslate.net/flags/svg/zh-CN.svg"> 简体中文
                  </a> -->
                </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
export default {
  components: {},

  data() {
    return {
      data: [{
          title: "English",
          code: "en",
          img: require("@/assets/english.png"),
      }, {
          title: "Português",
          code: "pt",
          img: require("@/assets/Portugues.png"),
      }, {
          title: "Español",
          code: "es",
          img: require("@/assets/Espanol.png"),
      }, {
          title: "Français",
          code: "fr",
          img: require("@/assets/Francais.png"),
      }, {
          title: "Deutschland",
          code: "de",
          img: require("@/assets/Deutsch.png"),
      }, {
          title: "Italia",
          code: "it",
          img: require("@/assets/Italiano.png"),
      }, {
          title: "Русский",
          code: "ru",
          img: require("@/assets/Pyccknn.png"),
      }, {
          title: "Chinese",
          code: "zh-CN",
          img: require("@/assets/Chinese.png"),
      }],
      is_default:{
        title:'English',
        code:'en',
        img:require("@/assets/english.png")
      },
      is_show:false,
      navList: [
        {
          url: "/about",
          text: "ABOUT",
        },
        {
          url: "/serve",
          text: "SERVICE",
        },
        {
          url: "/policy",
          text: "POLICY",
        },
        {
          url: "/blog",
          text: "BLOG",
        },
        {
          url: "/faq",
          text: "FAQ",
        },
        {
          url: "/help",
          text: "HELP&SUPPORT",
        },
      ],
      url: "",
    };
  },

  created() {},

  mounted() {
    this.loadGoogleTranslateScript();
    console.log(url);
    if (url == "/blog-detail") {
      this.url = "/blog";
    } else {
      this.url = url;
    }

  },

  methods: {
    loadGoogleTranslateScript() {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://translate.google.com/translate_a/element.js?cb=initializeGoogleTranslate`;
      script.async = true;
      script.onload = this.initializeGoogleTranslate;
      document.querySelector("html").appendChild(script);
    },
    initializeGoogleTranslate() {
      setTimeout(() => {
          console.log(333333);
          if (window.google && window.google.translate && window.google.translate.TranslateElement) {
            new window.google.translate.TranslateElement({
              pageLanguage: 'en',
              autoDisplay: false
            }, 'google_translate_element');
          } else {
            // 如果对象尚未加载完成，可以添加错误处理或者重试逻辑
            console.error('Google translate object not available yet.');
          }
      }, 1000); // 等待 1 秒后再尝试初始化
    },
    GTranslateFireEvent(element, event) {
      try {
        if (document.createEventObject) {
          var evt = document.createEventObject();
          var flag = element.fireEvent('on' + event, evt)
        } else {
          var evt = document.createEvent('HTMLEvents');
          evt.initEvent(event, true, true);
          var flag = element.dispatchEvent(evt)
        }
        setleftSidebarNum();
      } catch (e) {
      }
      return flag;
    },
     doGTranslate(lang) {
        var teCombo, flag = false;
        var sel = document.getElementsByTagName('select');
        for (var i = 0; i < sel.length; i++) if (sel[i].className.indexOf('goog-te-combo') != -1) {
          teCombo = sel[i];
          break;
        }
        if (document.getElementById('google_translate_element') == null || document.getElementById('google_translate_element').innerHTML.length == 0 || teCombo.length == 0 || teCombo.innerHTML.length == 0) {
          // setTimeout(function () {
          //     doGTranslate(lang)
          // }, 500)
        } else {
          teCombo.value = lang;
          flag = this.GTranslateFireEvent(teCombo, 'change');
        }
        if(this.GTranslateGetCurrentLang() == null && lang != 'en' && teCombo && teCombo.length && teCombo.innerHTML.length){
          doGTranslate(lang);
        }
        console.log(flag);
        return flag;
    },
    GTranslateGetCurrentLang() {
      var keyValue = document['cookie'].match('(^|;) ?googtrans=([^;]*)(;|$)');
      return keyValue ? keyValue[2].split('/')[2] : null;
    },
    translate(index){
      let data = this.data[index];
      this.is_default.title = data.title;
      this.is_default.img =  data.img;
      this.is_show = false;
      this.doGTranslate(data.code);
    },
    navTo(url) {
      if (this.url == url) return;
      this.url = url;
      this.$router.push(url);
    },


  },
};
</script>
<style lang="less" scoped>
.heard {
  width: 100%;
  height: 82px;
  background: #242424;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  .heard-center {
    width: 1220px;
    margin: 0 auto;
    img {
      width: 233px;
      height: 42px;
    }
    .item_nav {
      font-weight: bold;
      color: #ffffff;
      font-size: 14px;
      margin-left: 24px;
      transition: all 0.3s;
    }
    .item_nav:hover {
      color: #ff652b;
    }
    .item_nav_text:hover {
      color: #ffffff;
    }
    .item_nav_text {
      width: 72px;
      height: 34px;
      background: #ff652b;
      border-radius: 50px;
      text-align: center;
      line-height: 34px;
    }
    .item_nav_active {
      color: #ff652b;
      position: relative;
      &::before {
        content: "";
        width: 100%;
        height: 2px;
        background: #ff652b;
        position: absolute;
        bottom: -2px;
        left: 0;
      }
    }
  }
  .left{
    color: white;
    // padding: 0 20px;
    padding: 0px 120px 0px 0px;
    .gt_float_wrapper{
        position: relative;
        .gt_float_switcher{
          font-family: Arial;
          font-size: 20px;
          border-radius: 2px;
          color: #555;
          display: inline-block;
          line-height: 20px;
          box-shadow: rgba(0, 0, 0, 0.15) 0 5px 15px;
          background: #fff;
          overflow: hidden;
          transition: all .5s cubic-bezier(0.4, 0, 1, 1);
          .gt-selected{
              position: relative;
              z-index: 888;
              background-color: #fff;
              cursor: pointer;
              text-transform: uppercase;
              overflow: hidden;
              //width: 158px;
              .gt-current-lang{
                // padding: 13px 40px;
                // color: #333;
                // font-weight: bold;
                padding: 10px 15px;
                max-height: 250px;
                // margin-right: 52px;
                color: #333;
                font-weight: bold;
                img {
                  vertical-align: middle;
                  display: inline-block;
                  width: 33px;
                  height: auto;
                  margin: 0 5px 0 0;
                  border-radius: 3px;
                  border-style: none;
                  overflow-clip-margin: content-box;
                  overflow: clip;
                }
                .gt-lang-code{
                  position: relative;
                  top: 2px;
                }
                .gt_float_switcher-arrow{
                    display: inline-block;
                    height: 24px;
                    width: 15px;
                    vertical-align: middle;
                    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 285 285'><path d='M282 76.5l-14.2-14.3a9 9 0 0 0-13.1 0L142.5 174.4 30.3 62.2a9 9 0 0 0-13.2 0L3 76.5a9 9 0 0 0 0 13.1l133 133a9 9 0 0 0 13.1 0l133-133a9 9 0 0 0 0-13z' style='fill:%23666'/></svg>");
                    background-position: 50%;
                    background-size: 11px;
                    background-repeat: no-repeat;
                    transition: all .3s;
                    transform: rotate(-180deg);
                }
                .gt_arrow_rotate{
                  transform: rotate(0deg);
                }
              }

          }
          .gt_options{
        position: absolute;
        z-index: 777;
        max-height: 250px;
        overflow-y: auto;
        transform: translateY(-30px);
        opacity: 0;
        cursor: pointer;
        background-color: #ffffff;
        transition: all .8s cubic-bezier(.3,1.1,.4,1.1);
        img {
            vertical-align: middle;
            display: inline-block;
            width: 33px;
            height: auto;
            margin: 0 5px 0 0;
            border-radius: 3px;
        }
    }
    .gt_options.gt-open {
        opacity: 1;
        transform: translateX(0px);
    }
    .gt_options a {
        display: block;
        text-decoration: none;
        padding: 10px 15px;
        color: #444;
        transition: color .4s linear;
        background-color: transparent
    }
    .gt_options::-webkit-scrollbar{
      width: 5px;
    }
    .gt_options::-webkit-scrollbar-thumb {
          background-color: #888;
      }
      .gt_options::-webkit-scrollbar-track {
          background-color: #f5f5f5;
      }
      }
    }


  }
}
</style>

